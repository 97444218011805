<template>
  <div class="Index">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="hezuo pd40">
      <div class="container">
        <div class="headTit">
          <h3>Choose Your Favourite <span>City</span></h3>
        </div>
        <div>
          <el-row class="list"
                  :gutter="24">
            <el-col :span="8"
                    class="contsss">
              <div class="edulistCon infinite-list-wrapper">
                <div class="title">{{cityName}}</div>
                <div class="cont">
                  <el-scrollbar height="500px">
                    <div style="position: relative;">
                      <div class="loadingCont"
                           v-if="showLoading">
                        <Loading class="lii"></Loading>
                      </div>
                      <transition name="el-fade-in-linear">
                        <div v-if="!showLoading">
                          <div class="noSchool"
                               v-if="list.length == 0">
                            No Data
                          </div>
                          <div v-else>
                            <router-link :to="'/school-detail/'+item.id"
                                         v-for="(item,index) in list"
                                         :key="index">
                              <el-row class="lis"
                                      :gutter="24">
                                <el-col :span="12"
                                        class="imgbox">
                                  <img :src="item.thumb"
                                       alt="">
                                </el-col>
                                <el-col :span="12"
                                        class="text">
                                  <div class="head"> {{item.en_name}} </div>
                                  <div class="txt">City: {{item.city_name}} <br>
                                    QS Ranking: {{item.rank == 9999 ? '-' : item.rank}}
                                  </div>
                                </el-col>
                              </el-row>
                            </router-link>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </el-scrollbar>
                </div>
              </div>
            </el-col>
            <el-col :span="16">
              <div id="myChart123"
                   :style="{width: '100%', height: '700px'}"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { onMounted, getCurrentInstance, ref } from "vue";
import 'echarts/map/js/china.js'
import axiosApi from "../api/api.js";
import Loading from '@/components/LoadingBox'

export default {
  components: {
    Loading
  },
  data () {
    return {
      loading: false,
      // cityName: 'Beijing',
      cityCode: 110000,
      list: [],
      showLoading: true,
    }
  },
  setup () {
    const cityName = ref('Beijing')
    const provinceData = ref({})
    const provinceData2 = ref([])
    onMounted(() => { // 需要获取到element,所以是onMounted的Hook
      let myChart = echarts.init(document.getElementById("myChart123"));
      // 绘制图表
      let _data = getCurrentInstance().data

      axiosApi(
        'university/province', {
      }, 'get'
      ).then((res) => {
        console.log(res.data)
        res.data.forEach(item => {
          const obj = { name: item.en_name }
          for (let key in obj) {
            obj[item.name] = obj[key]
            delete obj[key]
          }
          provinceData2.value = [...provinceData2.value, { name: item.en_name, value: item.code }]
          provinceData.value = { ...provinceData.value, ...obj }
        })
      }).then(() => {
        myChart.on('click', function (param) {
          cityName.value = param.name
          _data.cityCode = param['data'].value
        })
        myChart.setOption({
          tooltip: {
            show: false
          },
          series: { // 这个是重点配置区
            type: 'map',
            map: 'china', // 表示中国地图
            roam: false,
            selectedMode: 'single',
            zoom: 1.23,
            center: [105, 36],
            label: {
              normal: {
                show: true, // 是否显示对应地名
                textStyle: {
                  color: '#333'
                }
              },
              emphasis: {
                show: true, // 是否显示对应地名
                textStyle: {
                  color: '#67b0f9'
                }
              }
            },
            itemStyle: {
              normal: {
                areaColor: "#E6EFFF",
                borderColor: "#a9a9a9",
                borderWidth: 0.5
              },
              emphasis: {
                areaColor: "#0c3879",
              }
            },
            data: provinceData2.value,
            nameMap: provinceData.value
          }
        });
        window.onresize = function () { // 自适应大小
          myChart.resize();
        };
      })

    });
    return {
      cityName
    };
  },
  beforeMount () {
    axiosApi(
      'university/searchschool/11', {
    }, 'get'
    ).then((res) => {
      this.showLoading = false
      this.list = res.data
    })
  },
  computed: {
  },
  watch: {
    cityName () {
      this.showLoading = true
      axiosApi(
        'university/searchschool/' + this.cityCode.slice(0, 2), {
      }, 'get'
      ).then((res) => {
        this.showLoading = false
        this.list = res.data
      })
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner5.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.hezuo {
  .infinite-list-item {
    padding: 10px;
    color: #666;
    display: flex;
    .imgbox {
      width: 150px;
    }
    .text {
      width: calc(100% - 150px);
      padding-left: 20px;
      h3 {
        color: #212121;
        line-height: 28px;
        font-size: 16px;
      }
      p {
        line-height: 1.7;
        font-size: 14px;
      }
    }
  }
  .contsss {
    padding-top: 20px;
  }
  .edulistCon {
    height: auto;
    overflow: auto;
    border: 1px solid #dedede;
    border-radius: 7px;
    position: relative;
    padding-top: 50px;

    .title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #0c3879;
      font-size: 20px;
      color: #fff;
      line-height: 50px;
      text-align: center;
    }
    .cons {
      padding: 10px;
    }
    .cont {
      padding: 10px;
      .lis {
        margin-bottom: 15px;
        width: 100%;
        .imgbox img {
          width: 100%;
          height: 95px;
        }
        .head {
          font-weight: bold;
          color: #333;
          margin-bottom: 10px;
        }
        .txt {
          font-size: 14px;
          color: #545454;
          line-height: 2;
        }
      }
    }
  }
  ._more {
    display: block;
    width: max-content;
    padding: 10px 30px;
    background-color: #0c3879;
    color: #fff;
    border-radius: 5px;
    margin: 20px auto 20px;
  }
}
.noSchool {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingCont {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 200px;
}
</style>
